import request from "./config";

export default class DistributionService {
  static create(data) {
    return request({
      url: "/distributions/create",
      method: "post",
      data,
    });
  }
  static delete(params) {
    return request({
      url: "/distributions",
      method: "delete",
      params:params
    });
  }
  static  findAll(params) {
    return request({
      url: `/distributions-all/list`,
      method: "get",
      timeout: 100000,
      params:params
    });
  }
  static  list(params) {
    return request({
      url: `/distributions`,
      method: "get",
      timeout: 100000,
      params:params
    });
  }
}
