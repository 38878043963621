import axios from "axios";
import Notiflix from 'notiflix';

const request = axios.create({
  baseURL: process.env.VUE_APP_URL || "/api/v1",
  // baseURL:"http://192.168.104.94:3000/api/v1",
});
let i=0;
let iClose=0;
request.defaults.timeout = 2500;
request.interceptors.request.use(
  (config) => {
    Notiflix.Loading.pulse();
    i++;
    var accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers["Authorization"] = accessToken;
    }
    return config;
  },
  (error) => {
    closeLoading()
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    closeLoading()
    if (response.status !== 200&&response.status !== 201) {
      return Promise.reject(response.message);
    }
    else if(response.status==200||response.status==201){
      if(response.data){
        if(response.data.message) Notiflix.Notify.success(response.data.message)
      }
    }
    return response;
  },
  (error) => {
    if(error.response){
      if(error.response.data) {
        if(error.response.data.message) Notiflix.Notify.failure(error.response.data.message)
      }
    }
    closeLoading()
    return Promise.reject(error);
  }
);

async function  closeLoading(){
  iClose++;
  if(i==iClose)
  {
    Notiflix.Loading.remove();
    i=0;
    iClose=0;

  }
}
export default request;
