import request from "./config";

export default class FilesService {
  static create(data) {
    return request({
      url: "/files/create",
      method: "post",
      data,
    });
  }
  static updateOrder(data) {
    return request({
      url: "/files/update-order",
      method: "put",
      data,
      timeout: 100000,
    });
  }
  static updateMove(data) {
    return request({
      url: "/files/update-move",
      method: "put",
      data,
      timeout: 100000,
    });
  }
  static uploadFiles(type,data) {
    return request({
      url: `/files/${type}`,
      method: "post",
      data,
      timeout: 100000,
    });
  }
  
  static update(id,data) {
    return request({
      url: `/files/update/${id}`,
      method: "put",
      data,
    });
  }
  static delete(id) {
    return request({
      url: `/files/${id}`,
      method: "delete",
    });
  }
  static  findAll(params) {
    return request({
      url: `/files-all/list`,
      method: "get",
      timeout: 100000,
      params:params
    });
  }
  static  findStockAll(params) {
    return request({
      url: `/files-all/list-stock`,
      method: "get",
      timeout: 100000,
      params:params
    });
  }
  
  static  find(params) {
    return request({
      url: `/files/list`,
      method: "get",
      timeout: 100000,
      params:params
    });
  }
}
