import request from "./config";

export default class ProductOutputService {
  static create(data) {
    return request({
      url: "/product-outputs",
      method: "post",
      data,
    });
  }
  static update(id,data) {
    return request({
      url: `/product-outputs/${id}`,
      method: "patch",
      data,
    });
  }
  static delete(id) {
    return request({
      url: `/product-outputs/${id}`,
      method: "delete",
    });
  }
  static  find(params) {
    return request({
      url: `/product-outputs`,
      method: "get",
      params:params
    });
  }
}
