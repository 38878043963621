import request from "./config";

export default class BusService {
  static create(data) {
    return request({
      url: "/buses",
      method: "post",
      data,
    });
  }
  static update(id,data) {
    return request({
      url: `/buses/${id}`,
      method: "patch",
      data,
    });
  }
  static delete(id) {
    return request({
      url: `/buses/${id}`,
      method: "delete",
    });
  }
  static  find(params) {
    return request({
      url: `/buses`,
      method: "get",
      params:params
    });
  }
}
