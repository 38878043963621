import request from "./config";

export default class GlobalCategoryService {
  static create(data) {
    return request({
      url: "/category",
      method: "post",
      data,
    });
  }
  static update(id,data) {
    return request({
      url: `/category/${id}`,
      method: "patch",
      data,
    });
  }
  static delete(id) {
    return request({
      url: `/category/${id}`,
      method: "delete",
    });
  }
  static  findAll(params) {
    return request({
      url: `/category`,
      method: "get",
      timeout: 100000,
      params:params
    });
  }
  static  find(params) {
    return request({
      url: `/category`,
      method: "get",
      timeout: 100000,
      params:{
        limit: 500,
        ...params
      }
    });
  }
}
