import request from "./config";
export default class ReportService {
  static list(query = {}) {
    return request({
      url: "/report",
      method: "get",
      timeout: 1000000,
      params: query,
    });
  }
  
  static reportType(query = {}) {
    return request({
      url: "/report/product-output",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static reportCategory(query = {}) {
    return request({
      url: "/report/product-category",
      method: "get",
      timeout: 10000,
      params: query,
    });
  }
  static reportStock(query = {}) {
    return request({
      url: "/report/product-min-stock",
      method: "get",
      timeout: 100000,
      params: query,
    });
  }
  static create(data){
    return request({
      url: "/report",
      method: "post",
      timeout: 100000,
      data,
    });
  }
  static changeStatus(data){
    return request({
      url: `/report/change-status/${data.id}`,
      method: "put",
      timeout: 100000,
      data,
    });
  }
  static delete(data){
    return request({
      url: `/report/delete/${data.id}`,// /api
      method: "delete",
      timeout: 100000,
    });
  }
  static update(id,data){
    return request({
      url: "/report/"+id,
      method: "put",
      timeout: 100000,
      data,
    });
  }
}
