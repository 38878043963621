import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/index';
// import 'vue-search-select/dist/VueSearchSelect.css'
// import '@vuepic/vue-datepicker/dist/main.css'
import moment from "moment";
import bootstrap from 'bootstrap'
import VueApexCharts from "vue3-apexcharts";

moment.locale('es')

createApp(App)
.use(router)
.use(VueApexCharts)
.component('apexchart', VueApexCharts)
.mount('#app')
