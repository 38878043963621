import {createRouter, createWebHistory} from 'vue-router';
import qs from 'qs'

// import DashboardSettings from '../components/routes/DashboardSettings'
import Applayouts from '../components/layouts/app.layouts.vue'
import Dashboardlayouts from '../components/layouts/dashboard.layouts.vue'
 const routes= [
    {
        path:"/",
        name:"/",
        component: Applayouts,
        children:[
            {
                path: "/",
                name: "login",
                component:()=>import('../components/pages/auth/login.page')
            },
        ]
    },
    {
        path: "/",
        name: "page",
        component: Dashboardlayouts,
        meta: {
            required: true,
            roles: ["user", "superadmin", "admin","guardia"],
        },
        children:[
            {
                path: "/dashboard",
                name: "dashboard",
                component:()=>import('../components/pages/admin/dashboard.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin","contabilidad", "admin","almacen"],
                },
            },
            
            {
                path: "/user",
                name: "user",
                component:()=>import('../components/pages/admin/user.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin",],
                },
            },
            {
                path: "/config/category",
                name: "category",
                component:()=>import('../components/pages/admin/store/category.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/store/unit",
                name: "unit",
                component:()=>import('../components/pages/admin/store/unit.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/store/make",
                name: "make",
                component:()=>import('../components/pages/admin/store/make.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/store/meter",
                name: "meter",
                component:()=>import('../components/pages/admin/store/meter.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/store/size",
                name: "size",
                component:()=>import('../components/pages/admin/store/size.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/store/product",
                name: "product",
                component:()=>import('../components/pages/admin/store/product.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/store/equipment",
                name: "productEquipment",
                component:()=>import('../components/pages/admin/store/equipment.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/config/code",
                name: "code",
                component:()=>import('../components/pages/admin/config/config.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/input/vendor",
                name: "vendor",
                component:()=>import('../components/pages/admin/input/vendor.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/input/product",
                name: "productInput",
                component:()=>import('../components/pages/admin/input/product-input.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/output/product",
                name: "productOutput",
                component:()=>import('../components/pages/admin/output/product-output.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/inventory",
                name: "inventory",
                component:()=>import('../components/pages/admin/inventory/inventory.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/inventory/report",
                name: "inventoryReport",
                component:()=>import('../components/pages/admin/report/report.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/inventory/report-product",
                name: "inventoryReportProduct",
                component:()=>import('../components/pages/admin/report/product.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/inventory/liquidation",
                name: "inventoryLiquidation",
                component:()=>import('../components/pages/admin/inventory/liquidation.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/inventory/summary",
                name: "inventorySummary",
                component:()=>import('../components/pages/admin/inventory/summary.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
            {
                path: "/inventory/order",
                name: "inventoryOrder",
                component:()=>import('../components/pages/admin/inventory/order.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", "contabilidad", ],
                },
            },
            {
                path: "/inventory/order-services",
                name: "inventoryServices",
                component:()=>import('../components/pages/admin/inventory/orderServices.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", "contabilidad", ],
                },
            },
            {
                path: "/inventory/requirements",
                name: "inventoryRequirements",
                component:()=>import('../components/pages/admin/inventory/requirements.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },

            {
                path: "/barcode",
                name: "barcode",
                component:()=>import('../components/pages/admin/barcode/barcode.page.vue'),
                meta: {
                    required: true,
                    roles: ["user", "superadmin", ],
                },
            },
        ]
    }
];

const router =createRouter(
    {
        history: createWebHistory(),
        routes,
        // parseQuery: qs.parse,
});
router.beforeEach((to, from, next) => {
    if (to.meta) {
        if(to.path=='/'){
            const token = localStorage.getItem("token") ;
            console.log(token)
            if(token){
                router.push("/dashboard");

            }
            else{
                next();
            }

        }
        else if (to.meta.required) {
            const user = JSON.parse(localStorage.getItem("user") || null);
            const token = localStorage.getItem("token") ;
            if (user) {
                if (token) {
                    if (to.meta.roles) {
                        let access = false;
                        for (let index = 0; index < to.meta.roles.length; index++) {
                            const element = to.meta.roles[index];
                            if (user.rol.includes(element)) {
                                access = true;
                            }
                        }
                        if (access) {
                            next();
                        } else {
                            router.push("/");
                        }
                    }
                    next();
                } else {
                    router.push("/");
                }
            }
            else {
                router.push("/");
            }
        }
    }
    next();
});
    
export default router;