import request from "./config";

export default class OrderService {
  static create(data) {
    return request({
      url: "/order",
      method: "post",
      data,
    });
  }
  static update(id,data) {
    return request({
      url: `/order/${id}`,
      method: "patch",
      data,
    });
  }
  static changeStatus(id,data) {
    return request({
      url: `/order/change-status/${id}`,
      method: "patch",
      data,
    });
  }
  
  static delete(id) {
    return request({
      url: `/order/${id}`,
      method: "delete",
    });
  }
  static  find(params) {
    return request({
      url: `/order`,
      method: "get",
      params:params
    });
  }
}
